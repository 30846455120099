import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import MetaTags from 'react-meta-tags';
function About() {
    return (
        <React.Fragment>
            <MetaTags>
                <title>ENTMCQ - Your Source for Medical Exam Preparation MCQs</title>
                <meta name="description" content="ENT MCQ: The online education company for practice tests, mock exams, and remedial analysis for FRCS (ORL-HNS).Achieving Excellence in Medical Exams" />
                <meta property="og:title" content="About us-ENT MCQ - Your Medical Exam Prep" />
                <meta property="keywords" content="frcs in medical, frcs mcq, Single Best Answer (SBA), examination preparation, FRCS training, medical revision, frcs exam, mcq bank app, frcs mcq, UK-based ENT surgeons, frcs ent,  FRCS curriculum, ENT FRCS exams, ent mcq, mcq ent, Frcs"/>
                <link rel="canonical" href={window.location.href}/>
            </MetaTags>
            <div class="container-fluid">
                <div class="row">
                    <Header />

                </div>
            </div>

            <div class="container-fluid" id="aboutus">
                <div class="row">
                    <div class="col-sm-12">
                        <div class="container">
                            <div class="row align-items-center">
                                <div class="col-sm-6 col-12 d-flex justify-content-between">
                                    <h1>About ENT MCQ</h1>

                                </div>
                                {/* <div class="col-sm-6 col-12 d-flex justify-content-between">
                                    <nav aria-label="breadcrumb">
                                        <ol class="breadcrumb mb-0" id="breadcrumb">
                                            <li class="breadcrumb-item"><a href="#">Home</a> </li>
                                            <li class="breadcrumb-item active" aria-current="page">About ENT MCQ</li>
                                        </ol>
                                    </nav>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container pb-5" id="main-content">
                <div class="row">
                    <div class="col-lg-6 col-12">
                        <h3 class="mb-4" style={{fontWeight:'bold'}}>About ENT MCQ</h3>
                        <p style={{ textAlign: 'justify' }}>Welcome – This website is especially created and designed to enable you to prepare for and maximise your chances of passing the FRCS (ORL-HNS) Section One Examination.</p>
                        <p style={{ textAlign: 'justify' }}>Our website was created by and is regularly updated by trainees and new consultants who have successfully completed the FRCS (ORL-HNS) Section One examination on their first sitting in the past few years. It is the ONLY website and app for this specific examination that is dedicated to help you succeed.</p>
                        <p style={{ textAlign: 'justify' }}>As an adjunct to your learning, it will enable you to apply theory into examination practice, consolidate your learning and improve your confidence and chances of passing - prior to the big day itself.</p>
                        <p class="fw-bold">Our unique features:</p>
                        <ul>
                            <li><p style={{ textAlign: 'justify' }}>The only website that exists specifically for this particular examination, unlike other generic multiple choice websites</p></li>
                            <li><p style={{ textAlign: 'justify' }}>Designed and maintained by UK doctors who have/had national training numbers in ENT, who have passed this examination</p></li>
                            <li><p style={{ textAlign: 'justify' }}>Option for practicing by sub-specialty or randomly generated quiz modes</p></li>
                            <li><p style={{ textAlign: 'justify' }}>Option to time yourself and perform ‘mock exams’ replicating the real exam</p></li>
                            <li><p style={{ textAlign: 'justify' }}>Our bank has answers and explanations to ALL questions as well as more information/details or links to resources</p></li>
                            <li><p style={{ textAlign: 'justify' }}>Our content is regularly reviewed and updated as per the JCST curriculum and guideline changes that take place in the UK</p></li>
                            <li><p style={{ textAlign: 'justify' }}>Option to be paid by us if you submit questions for our SBA question bank</p></li>
                        </ul>
                    </div>
                    <div class="col-sm-6">
                        <img src="/assets/images/aboutus.svg" class="img-fluid" />
                    </div>
                    <div class="col-sm-12">
                        <p style={{ textAlign: 'justify' }}>This website is by no means a recommendation as your only preparation method for your upcoming examination. We highly recommend you undertake prior theoretical learning, use multiple learning resources and use this website as an adjunct to apply theory into examination practice and consolidate your learning prior to sitting the examination. An important pearl to remember is that this examination is ‘Single Best Answer’, regardless of whether you think that none of the options are realistic, viable or appropriate – you HAVE to select an answer.</p>
                        <p style={{ textAlign: 'justify' }}>These questions have been designed and created based on the past experience of our highly successful team members, who all passed this examination recently on their first attempt/sitting. They have constructed these questions from scratch, based on the new ISCP Otolaryngology curriculum (August 2022) and align with the themes and learning objectives outlined and approve by the GMC, HEE and the JCST.</p>
                        <p style={{ textAlign: 'justify' }}>It is prudent to note that any resemblance of our questions to other available learning resources either published, printed or online, are purely coincidental. Our team regularly review and update questions to match real clinical practice and are all initially made based on the higher surgical training curriculum, we always appreciate feedback and suggestions for change, so please do reach out to us if you feel something can be improved.</p>
                        <p style={{ textAlign: 'justify' }}>Being the only dedicated practice question bank platform available for this examination, we feel we should give back as people contribute. We have therefore made a submission section where you will be invited in the near future to submit relevant and appropriate questions with answers. If approved to add to our question bank, you will be paid £5 per 5 questions accepted, by BACS bank transfer directly into your UK bank account.</p>
                    </div>
                </div>
            </div>
            <Footer />

        </React.Fragment>
    )
}

export default About;