import React from 'react';
import Header from "./Header";
import Footer from "./Footer";
import MetaTags from 'react-meta-tags';
function Termsconditions () {
    return (
        <React.Fragment>
            <MetaTags>
                <title>Explore ENT MCQ's Comprehensive Terms and Conditions</title>
                <meta name="description" content="Welcome to ENT MCQ! These terms and conditions outline the rules for the use of our platform, dedicated to providing effective preparation materials for the FRCS exam." />
                <meta property="og:title" content=" Explore ENT MCQ's Comprehensive Terms and Conditions" />
                <link rel="canonical" href={window.location.href}/>
                {/* <meta property="keywords" content="frcs ent section one exam, frcs mcq exam, frcs sba exam" /> */}
            </MetaTags>
            <Header/>
             <div>
            <div class="container-fluid" id="aboutus">
                <div class="row">
                    <div class="col-sm-12">
                        <div class="container">
                            <div class="row align-items-center">
                                <div class="col-sm-12 d-flex justify-content-between">
                                    <h1>Terms And Conditions</h1>
                                    {/* <nav aria-label="breadcrumb">
                                        <ol class="breadcrumb mb-0" id="breadcrumb">
                                            <li class="breadcrumb-item"><a href="/">Home</a></li>
                                            <li class="breadcrumb-item active" aria-current="page">Terms And Conditions</li>
                                        </ol>
                                    </nav> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container pb-5" id="main-content">
            <div class="row">
            <div class="col-sm-12">
                {/* <h3 class="mb-4">Terms And Conditions</h3> */}
                <h3 class="mb-4">TERMS OF SALE OF ONLINE TUITION ETC BY TUTOR, TEACHER, TRAINER, INSTRUCTOR, COACH, MENTOR FOR ADULTS</h3>
                <h5><b>BACKGROUND:</b></h5>
                <p>These Terms of Sale together with the attachment below and any and all other 
                    documents referred to in these Terms of Sale set out the terms and conditions on
                    which Paid Content, accessed via Subscriptions, is sold by Us to Consumers through 
                    this website, www.entmcq.com (“Our Site”).</p>
                <p>Terms and information that are specific to accessing sessions of Paid Content
                    presented by a Tutor (as defined below) from or via Our Site using the platform are 
                    set out in the attachment below for ease of reference but they will have the same 
                    effect as if set out in these Terms of Sale.
                </p>
                <p>Please read these Terms of Sale and the attachment below carefully and ensure that 
                    You understand them before purchasing a Subscription. If You have any query about 
                    anything in these Terms of Sale or the attachment, please contact Us to discuss.
                    <b><u>When setting up an Account before purchasing any Subscription, You will be 
                    required to read, accept, and agree to comply with and be bound by these 
                    Terms of Sale</u> and the attachment.</b> If You do not, You will not be able to purchase a 
                    Subscription and access Paid Content through Our Site. </p>
                    <p>All of the information that We give to You will be part of the terms of Our Contract with 
                    You as a Consumer whether it is information that We:<br/>
                    <ul type="1">
                    <li> are required by law to give to You before You order a Subscription; or </li>
                    <li> voluntarily give to You and You rely on it either when deciding to order a 
                    Subscription or when, subsequently, You make any decision about the 
                    Subscription</li></ul>
                    We give You some of that information before You order a Subscription and some it is 
set out in these Terms of Sale and the attachment.</p>
<p>Paid Content is intended for access and use only by a person who is aged 18 or over, 
and only a person of that age can set up an Account and purchase a Subscription.</p>
<p>These Terms of Sale, as well as any and all Contracts, are in the English language 
only.</p>
<p>These Terms of Sale apply only to the sale of Paid Content; the terms governing use 
of Our Site are separate and are set out on our Site under the heading “Website 
Terms of Use”.</p>
<ol>
<p class="fw-bold"><li>Definitions and Interpretation :</li></p>
    <ol>
        <p><li> In these Terms of Sale, unless the context otherwise requires, the following 
        expressions have the following meanings:</li></p>
        <table>
            <tr>
                <th><p>“Account”</p></th>
                <td><p style={{marginLeft:"10%"}}>means the account, referred to in sub-Clause 7.1, that You must set
                up with Us in order to purchase any Subscription;</p></td>
            </tr>
            <tr>
                <th><p>“Background Items”</p></th>
                <td><p style={{marginLeft:"10%"}}>means background and other information or materials relating to or 
        forming part of the tuition, instruction, teaching, coaching, training, 
        or mentoring to be provided, all of which information or materials is 
        downloadable or viewable as text/graphics;</p></td>
            </tr>
            <tr>
                <th><p>“Consumer”</p></th>
                <td><p style={{marginLeft:"10%"}}>means an individual customer who purchases any Subscription to 
Our services comprising any Paid Content which is to be received
or used for their personal use and for purposes wholly or mainly 
outside the purposes of any business, trade, craft or profession;</p></td>
            </tr>
            <tr>
                <th><p>“Contract”</p></th>
                <td><p style={{marginLeft:"10%"}}>means a contract between Us and You for the purchase of a 
Subscription for You to access any Paid Content, as explained in 
Clause 7;</p></td>
            </tr>
            <tr>
                <th><p>“Paid Content”</p></th>
                <td><p style={{marginLeft:"10%"}}>means any content (including text, graphics, images, audio, and 
            video) comprising any session of tuition, instruction, teaching, 
            coaching, training, or mentoring, or any Background Items or other 
            materials or information, which We offer. The sessions, Background
            Items and other information or materials are sold by Us through Our 
            Site and made available by Us [[on] [or] [via] Our Site] by means of:</p>
            <p style={{marginLeft:"10%"}}>[two-way synchronous livestream audio and/or video technology,
            e.g. Zoom, GoToMeeting, Skype for Business (part of Teams), 
            WebEx;]</p>
            <p style={{marginLeft:"10%"}}>[asynchronous livestream video and/or audio;]</p>
            <p style={{marginLeft:"10%"}}>[recorded streamed non downloadable video and/or audio;] and
downloadable (or other viewable) text, graphics or other video,
audio, or other items or information, including Background Items</p>
<p style={{marginLeft:"10%"}}>Paid Content will be more fully described in other information that 
We give or make available to You before You order a Subscription.
That information may include the name of any Tutor presenting any
content on Our behalf but whether it does or does not do so, We 
may, if so We decide in our discretion, at any time and without
notice substitute any other Tutor(s) who is suitably qualified and 
experienced;</p>
            </td>
            </tr>
            <tr>
            <th><p>“Subscription”</p></th>
            <td><p style={{marginLeft:"10%"}}>means a subscription to Our Site purchased by You which provides
            You with the entitlement and access to Paid Content which 
            comprises either:
            <ol style={{marginLeft:"10%"}} type="a">
                <li>one or more specific single events or items; and/or</li>
                <li>one or more series or collections of two or more specific events or items; and/or</li>
                <li>one or more or all types of events or items available on or via Our Site; and</li>
                <li>the Background Items</li>
            </ol>
            We will give You information about the times and dates or periods
of access to (a), (b) and (c) before You purchase the Subscription 
as to which, see sub-Clause 7.4.6)<br/>
Every Subscription will include access to the Background Items for 
the whole period of the Subscription, and they will all be accessible
as soon as We have sent You a Subscription Confirmation;
</p>
            </td>
            </tr>
            <tr>
                <th><p>“Subscription Confirmation”</p></th>
                <td><p style={{marginLeft:"10%"}}>means Our acceptance and confirmation of Your purchase of a Subscription;</p></td>
            </tr>
            <tr>
                <th><p>“Subscription ID”</p></th>
                <td><p style={{marginLeft:"10%"}}>means the reference number for Your Subscription;</p></td>
            </tr>
            <tr>
                <th><p>“Tutor”</p></th>
                <td><p style={{marginLeft:"10%"}}>means the tutor, instructor, teacher, coach, mentor, or other 
individual who presents the Paid Content and/or who interacts with 
You online during any session of Paid Content;</p></td>
            </tr>
            <tr>
                <th><p>“We/Us/Our”</p></th>
                <td><p style={{marginLeft:"10%"}}>means ENT Educational Courses Ltd, a company registered in 
England, whose registered address is 61, Bridge Street, Kington, Herefordshire, HR5 3DJ. and</p></td>
            </tr>
            <tr>
                <th><p>“You”</p></th>
                <td><p style={{marginLeft:"10%"}}>means the Consumer who sets up an Account and purchases a
Subscription and accesses and uses any Paid Content.</p></td>
            </tr>
        </table>
    </ol>
    <p class="fw-bold"><li>Information About Us</li></p>
    <ol type='1'>
    <p><li>Our Site, www.entmcq.com, is owned and operated by ENT Educational 
        Courses Ltd, a limited company registered in whose registered address is 61, 
        Bridge Street, Kington, Herefordshire, HR5 3DJ and whose main trading 
        address is also at 61, Bridge Street, Kington, Herefordshire, HR5 3DJ.</li></p>
    </ol>
    <p class="fw-bold"><li>Contacting Us</li></p>
    <ol>
    <p><li> If You wish to contact Us with general questions, You may contact Us by 
email at support@entmcq.com, or by post at ENT Education Courses Ltd. 61, 
Bridge Street, Kington, Herefordshire, HR5 3DJ.</li></p>
<p><li>For matters relating to Paid Content or Your Subscription or Account, please 
contact Us by email at support@entmcq.com, or by post at the above address.
</li></p>
<p><li>For matters relating to cancellations, please contact Us by email at 
<a href=""> support@entmcq.com</a>, or in writing by post at ENT Education Courses Ltd. 61, 
Bridge Street, Kington, Herefordshire, HR5 3DJ, or refer to the relevant 
Clauses above.

</li></p>
<li><p>To make a complaint, see Clause 15.
</p></li>
    </ol>
    <p class="fw-bold"><li>Age Restriction and Consumers only</li></p>
    <p>Only if a person is aged at least 18 years of age and a Consumer may they set up an 
    Account or purchase a Subscription through Our Site and access Paid Content.</p>
    
    <p class="fw-bold"><li>Business Customers</li></p>
    <p>These Terms of Sale and the attachment below do not apply to customers purchasing 
        Subscriptions and accessing Paid Content in the course of any business trade, craft 
        or profession carried on by either them or any other person/organisation.
    </p>
    <p class="fw-bold"><li>Subscriptions, Paid Content, Pricing and Availability</li></p>
    <ol type='1'>
    <p><li>We make all reasonable efforts to ensure that all descriptions of Subscriptions 
and Paid Content available from Us correspond to the actual Subscription and 
Paid Content that You will receive
    </li></p>
    <p><li>Please note that sub-Clause 6.1 does not exclude Our responsibility for 
mistakes due to negligence on Our part and refers only to minor discrepancies
between the Paid Content and the descriptions of it. Please refer to Clause 11
if Your Subscription or the Paid Content is incorrect.]
</li></p>
    <p><li>We may from time to time change Our prices. Changes in price will not affect 
any Subscription that You have already purchased but will apply to any 
subsequent renewal or new Subscription. We will inform You of any change in 
price at least 14 days before the change is due to take effect. If You do not 
agree to such a change, You may cancel the Contract as described in subClause 13.1.
</li></p>
<p><li>Minor changes may, from time to time, be made to certain Paid Content, for 
example, to reflect changes in relevant laws and regulatory requirements, or to
address technical or security issues. These changes will not alter the main 
characteristics of the Paid Content and should not normally affect Your use of 
that Paid Content. However, if any change is made that would affect Your use 
of the Paid Content, suitable information will be provided to You.</li></p>
<p><li>In some cases, as described in the relevant content descriptions, We may also 
make more significant changes to the Paid Content. If We do so, We will 
inform You at least 14 days before the changes are due to take effect. If You
do not agree to the changes, You may cancel the Contract as described in 
sub-Clause 13.1.</li></p>
<p><li>Where any updates are made to Paid Content, that Paid Content will continue 
to match Our description of it as provided to You before You purchased Your
Subscription for access to the Paid Content. Please note that this does not 
prevent Us from enhancing the Paid Content, thereby going beyond the
original description.
</li></p>
<p><li>We make all reasonable efforts to ensure that all prices shown on Our Site are 
correct at the time of going online. All pricing information is reviewed and 
updated every 12 months. Changes in price will not affect any order for a 
Subscription that You have already placed (please note sub-Clause 6.11
regarding VAT, however).</li></p>
<p><li>All prices are checked by Us before We accept Your order. In the unlikely 
event that We have shown incorrect pricing information, We will contact You in 
writing to inform You of the mistake. If the correct price is lower than that 
shown when You made Your order, We will simply charge You the lower 
amount and continue processing Your order. If the correct price is higher, We 
will give You the option to purchase the Subscription at the correct price or to 
cancel Your order (or the affected part of it). We will not proceed with processing Your order in this case until You respond. If We do not receive a 
response from You within 7 (seven days), We will treat Your order as 
cancelled and notify You of this in writing 
</li></p>
<p><li>If We discover an error in the price or description of Your Subscription after 
Your order is processed, We will inform You immediately and make all 
reasonable efforts to correct the error. You may, however, have the right to 
cancel the Contract if this happens. If We inform You of such an error and You
do wish to cancel the Contract, please refer to sub-Clause 13.4.</li></p>
<p><li>If the price of a Subscription that You have ordered changes between Your
order being placed and Us processing that order and taking payment, You will 
be charged the price shown on Our Site at the time of placing Your order.</li></p>
<p><li>All prices on Our Site include VAT. If the VAT rate changes between Your
order being placed and Us taking payment, the amount of VAT payable will be 
automatically adjusted when taking payment.</li></p>
    </ol>
    <p class="fw-bold"><li>Orders – How Contracts Are Formed</li></p>
    <ol>
        <p><li>Our Site will guide You through the process of setting up an Account and 
purchasing a Subscription. (Please also see the attachment below as to 
setting up an Account.) Before completing Your purchase of a Subscription, 
You will be given the opportunity to review Your order for the Subscription and
amend it. Please ensure that You have checked Your order carefully before 
submitting it.</li></p>
<p><li>If, during the order process, You provide Us with incorrect or incomplete 
information (including any incorrect or incomplete information about You or the
type of Paid Content that You require) please contact Us as soon as possible. 
If We are unable to process Your order due to incorrect or incomplete 
information, We will contact You to ask to correct it. If You do not give Us the 
accurate or complete information within a reasonable time of Our request, We 
will cancel Your order and treat the Contract as being at an end. We will not 
be responsible for any delay in the availability of Paid Content that results from 
You providing incorrect or incomplete information.
</li></p>
<p><li>No part of Our Site constitutes a contractual offer capable of acceptance. 
Your order to purchase a Subscription constitutes a contractual offer that We 
may, at Our sole discretion, accept. Our acknowledgement of receipt of Your
order does not mean that We have accepted it. Our acceptance is indicated by 
Us sending You a Subscription Confirmation by email. Only once We have 
sent You a Subscription Confirmation will there be a legally binding Contract
between Us and You.</li></p>
<p><li>Subscription Confirmations shall contain the following information:
<ol>
    <li>Your Subscription ID;</li>
    <li>Confirmation of the Subscription ordered including full details of the 
main characteristics of the Subscription and Paid Content available 
as part of it;</li>
<li>Fully itemised pricing for Your Subscription including, where 
appropriate, taxes, and other additional charges;</li>
<li>The duration of Your Subscription (including the start date, and the 
[expiry] [and][or] [renewal date]);</li>
<li>Confirmation of Your acknowledgement that the Paid Content will be 
made available to You immediately and that You will lose Your lega right to change Your mind and cancel upon accessing the Paid Content as detailed below in sub-Clause 12.1;</li>
<li>In relation to any video (live or recorded) event, item, series, 
collection or type/s of events or items or Background Items 
constituting the Paid Content, the time/date when or period during 
which it can be accessed; and</li>
</ol>
</li></p>
<p><li>In the unlikely event that We do not accept or cannot fulfil Your order for any 
reason, We will explain why in writing. No payment will be taken under normal 
circumstances. If We have taken payment any such sums will be refunded to 
You as soon as possible and in any event within 14 days (fourteen days).</li></p>
<p><li>Any refunds under this Clause 7 will be issued to You as soon as possible, 
and in any event within 14 calendar days of the day on which the event 
triggering the refund occurs.</li></p>
<p><li>Refunds under this Clause 7 will be made using the same payment method 
that You used when purchasing Your Subscription [unless You specifically 
request that We make a refund using a different method].</li></p>
    </ol>
    <p class="fw-bold"><li>Payment for Subscriptions</li></p>
    <ol>
        <p><li>Payment for each Subscription must always be made in advance in full. Your 
chosen payment method will be charged when We process Your order and 
send You a Subscription Confirmation [(this usually occurs immediately and 
You will be shown a message confirming Your payment)].</li></p>
<p><li>We accept the following methods of payment on Our Site:
<ol>
    <li>Visa Debit Card;</li>
    <li>Visa Credit Card;</li>
    <li>Master Credit Card;</li>
    <li>American Express.</li>
    <li>Apple pay</li>
    <li>Google Pay</li>
</ol></li></p>
<p><li>If You do not make any payment due to Us on time, We will suspend Your
access to the Paid Content. For more information, please refer to sub-Clause 
9.6.</li></p>
<p><li>Any outstanding sums due to Us will remain due and payable.</li></p>
<p><li>If You believe that We have charged You an incorrect amount, please contact 
Us at support@entmcq.com as soon as reasonably possible to let Us know. 
You will not be charged for Paid Content while availability is suspended. </li></p>
    </ol>
    <p class="fw-bold"><li>Provision of Paid Content</li></p>
    <ol>
        <p><li>We undertake to make available to You on these Terms of Sale the Paid 
        Content for which You subscribe but if You choose not to access or make any
        permitted use of some or all of that Paid Content or, for any reason not 
        attributable to Us, You are unable to do so, You will not be entitled to any 
        refund.</li></p>
        <p><li>All Paid Content within the scope of Your Subscription will be available to You
        in accordance with sub-Clause 7.4.6 from when We send You a Subscription
        Confirmation for the duration of Your Subscription, including any renewals, or until You end the Contract, on and subject to the following:
        <ol>
            <li>An item of Paid Content requested will be available when stated in the 
        information that We provide about it before You place Your order, 
        either (a) if it is a livestream item, the time and date when it is
        scheduled to be available and to start; or (b) if it is a pre-recorded or
        other non livestreamed item or Background Item, the period within 
        which it is or will be available for access. </li>
        </ol></li></p>
        <p><li>When You place an order for a Subscription, You will be required to expressly 
        acknowledge that You wish Paid Content to be made available to You to 
        access immediately. You will also be required to expressly acknowledge that
        by accessing (e.g. downloading or streaming) any Paid Content, You will lose 
        Your legal right to cancel if You change Your mind (the “cooling-off period”). 
        Please see sub-Clause 12.1 for more information.
        </li></p>
        <p><li>In some limited circumstances, We may need to suspend the provision of Paid 
        Content (in full or in part) for one or more of the following reasons:
        <ol>
            <li>To fix technical problems or to make necessary minor technical changes;</li>
            <li>To update the Paid Content to comply with relevant changes in the law or other regulatory requirements;</li>
            <li>To make more significant changes to the Paid Content, as described above in sub-Clause 6.5.</li>
        </ol></li></p>
        <p><li>If We need to suspend availability of the Paid Content for any of the reasons 
        set out in sub-Clause 9.4, We will inform You in advance of the suspension 
        and explain why it is necessary (unless We need to suspend availability for
        urgent or emergency reasons such as a dangerous problem with the Paid 
        Content, in which case We will inform You as soon as reasonably possible
        after suspension). You may end the Contract as described below in subClause 13.2.</li></p>
        <p><li>We may suspend provision of the Paid Content as follows if We do not receive
        payment on time from You. We will inform You of the non-payment on the due 
        date, however if You do not make payment within 7 (seven days) of Our
        notice, We may suspend provision of the Paid Content until We have received 
        all outstanding sums due from You. If We do suspend provision of the Paid 
        Content, We will inform You of the suspension. You will not be charged for any 
        Paid Content while provision is suspended.</li></p>
        <p><li>Any refunds under this Clause 9 will be issued to You as soon as possible, 
        and in any event within 14 calendar days of the day on which the event 
        triggering the refund occurs.
        </li></p>
        <p><li>Refunds under this Clause 9 will be made using the same payment method 
        that You used when purchasing Your Subscription.
        </li></p>
    </ol>
    <p class="fw-bold"><li>Licence</li></p>
    <ol>
        <p><li>When You purchase a Subscription to access Paid Content, We will grant You
        a limited, non-exclusive, non-transferable, non-sublicensable licence for You
        to access, participate in and use the relevant Paid Content for Your personal,
        non-commercial purposes. The licence granted does not give You any rights
        in Our Paid Content (including any material that We may licence from third 
        parties).</li></p>
        <p>
            <li>The licence granted under sub-Clause 10.1 is subject to the following usage 
            restrictions and/or permissions:
            <ol>
                <li>You may not copy, rent, sell, publish, republish, share, broadcast or 
                otherwise transmit the Paid Content (or any part of it) or make it
                available to the public except as permitted under the Copyright
                Designs and Patents Act 1988 (Chapter 3 ‘Acts Permitted in relation 
                to Copyright Works’); [and]
                </li>
                <li>When You use a two-way livestream facility to access a Paid Content
                item or event You must not communicate or make accessible to any 
                other person (who also accesses or participates in it as one of Our
                customers) anything (by voice, text, image or otherwise) except for a
                query about or contribution to that item or event which is proper
                having regard to the content of it; [and]
                </li>
            </ol>
            </li>
        </p>
    </ol>
    <p class="fw-bold"><li>Problems with the Paid Content</li></p>
    <ol>
        <p><li>We undertake to provide Paid Content that is of satisfactory quality, fit for 
        purpose, and as described, and to use reasonable care and skill. If any Paid 
        Content available through Your Subscription does not comply or We do not so 
        act, please contact Us as soon as reasonably possible to inform Us of the 
        problem. Your available remedies will be as follows:
        
        <ol>
            <li>If the Paid Content has faults, You will be entitled to a repair or a replacement</li>
            <li>If We cannot fix the problem, or if it has not been (or cannot be) fixed within a reasonable time and without significant inconvenience to You, You may be entitled to a full or partial refund.</li>
            <li>If You can demonstrate that the fault has damaged Your device or other content belonging to You because We have not used reasonable care and skill, You may be entitled to a repair or compensation. Please refer to sub-Clause 14.3 for more information.</li>
        </ol></li></p>
        <p><li>[Please note that We will not be liable under this Clause 11 if We informed
            You of the fault(s) or other problems with particular Paid Content before You
            accessed it and it is that same issue that has now caused the problem (for
            example, if the Paid Content in question is an alpha or beta version and We 
            have warned You that it may contain faults that could harm Your device or 
            other content), if You have purchased the Paid Content for an unsuitable 
            purpose that is neither obvious nor made known to Us and the problem has 
            resulted from Your use of the Paid Content for that purpose; or if the problem 
            is the result of misuse or intentional or careless damage.]</li></p>
        <p><li>If there is a problem with any Paid Content, please contact Us at 
        support@entmcq.com or visit the contact page on Our Site www.entmcq.com
        to inform us of the problem.
        </li></p>
        <p><li>Refunds (whether full or partial, including reductions in price) under this 
        Clause 11 will be issued within 14 calendar days of the day on which We 
        agree that You are entitled to the refund.
        </li></p>
        <p><li>Refunds under this Clause 11 will be made using the same payment method
        that You used when purchasing Your Subscription.</li></p>
        <p><li>For further information on Your rights as a consumer, please contact Your
        local Citizens’ Advice Bureau or Trading Standards Office.
        </li></p>
    </ol>
    <p class="fw-bold"><li>Your Other Rights to End the Contract</li></p>
    <ol>
        <p><li>You may end the Contract at any time if We have informed You of a 
        forthcoming change to Your Subscription or the Paid Content (as described in
        sub-Clauses 6.3 or 6.5), or to these Terms of Sale that You do not agree to. If 
        the change is set to take effect or apply to You before the end of Your current 
        Subscription, no refunds will be given. If the change will not take effect or 
        apply to You until the expiry of Your current Subscription, the Contract will end 
        at the end of that Subscription period and You will continue to have access to 
        the Paid Content until that date.
        </li></p>
        <p><li>If We have suspended availability of the Paid Content for more than six
        weeks, or We have informed You that We are going to suspend availability for 
        more than six weeks, You may end the Contract immediately, as described in 
        sub-Clause 9.5. If You end the Contract for this reason, We will issue You with 
        a partial refund.</li></p>
        <p><li>If there is a risk that availability of the Paid Content will be significantly delayed 
        because of events outside of Our control, You may end the Contract 
        immediately. If You end the Contract for this reason, We will issue You with a 
        full refund.</li></p>
        <p><li>If We inform You of an error in the price or description of Your Subscription or 
        the Paid Content and You wish to end the Contract as a result, You may end it 
        immediately. If You end the Contract for this reason, We will issue You with a 
        partial refund.</li></p>
        <p><li>You also have a legal right to end the Contract at any time if We are in breach
        of it. You may also be entitled to a full or partial refund and compensation. For 
        more details of Your legal rights, please refer to Your local Citizens Advice 
        Bureau or Trading Standards Office.</li></p>
        <p><li>Refunds under this Clause 13 will be made within 14 calendar days of the date 
        on which Your cancellation becomes effective, using the same payment 
        method that You used when purchasing Your Subscription [unless You
        specifically request that We make a refund using a different method].</li></p>
        <p><li>If You wish to exercise Your right to cancel under this Clause 13, You may do 
        so in any way You wish, contact Us directly to cancel, please use the following 
        details:
        <ol>
            <li>Email: support@entmcq.com;</li>
            <li>Post: 61, Bridge Street, Kingtone, Herefordshire, HR5 3DJ</li>
        </ol>
        in each case, providing Us with Your name, address, email address, 
        telephone number and Subscription ID.
        </li></p>
        <p><li>We may ask You why You have chosen to cancel and may use any answers
        You provide to improve Our content and services, however please note that
        You are under no obligation to provide any details if You do not wish to.</li></p>
    </ol>
    <p class="fw-bold"><li>Our Liability to Consumers</li></p>
    <ol>
        <p><li>We will be responsible for any foreseeable loss or damage that You may 
        suffer as a result of Our breach of these Terms of Sale (or the Contract) or as 
        a result of Our negligence. Loss or damage is foreseeable if it is an obvious
        consequence of Our breach or negligence or if it is contemplated by You and 
        Us when the Contract is created. We will not be responsible for any loss or 
        damage that is not foreseeable.
        </li></p>
        <p><li>Our Paid Content is intended for non-commercial use only. We make no
        warranty or representation that the Paid Content is fit for commercial, business 
        or industrial use of any kind. We will not be liable to You for any loss of profit, 
        loss of business, interruption to business, or for any loss of business 
        opportunity.</li></p>
        <p><li>If, as a result of Our failure to exercise reasonable care and skill, any content 
        (including but not limited to Paid Content) from Our Site damages Your device
        or other content belonging to You, We will either repair the damage or pay 
        You appropriate compensation. Please note that We will not be liable under 
        this provision if:
        <ol>
            <li>We have informed You of the problem and provided a free update 
        designed to fix it, but You have not applied the update; or
        </li>
        <li>The damage has been caused by Your own failure to follow Our
        instructions; or</li>
        <li>Your device does not meet any relevant minimum system 
        requirements that We have made You aware of before You
        purchased Your Subscription.</li>
        </ol></li></p>
        <p><li>Nothing in these Terms of Sale seeks to limit or exclude Our liability for death
        or personal injury caused by Our negligence (including that of Our employees, 
        agents or sub-contractors); or for fraud or fraudulent misrepresentation, or for 
        Paid Content which is not as described, does not match information that We 
        provided, not of satisfactory quality, or is not fit for any purpose made known 
        to Us.</li></p>
        <p><li>Nothing in these Terms of Sale seeks to exclude or limit Your legal rights as a 
        consumer. For more details of Your legal rights, please refer to Your local 
        Citizens Advice Bureau or Trading Standards Office.
        </li></p>
        <p><li>We will not be responsible or liable if You are unable to access any Paid
        Content due to any failure or delay in performing Our obligations under the 
        Contract resulting from any cause beyond Our reasonable control (including 
        but not limited to a cause of that type specifically referred to in the
        Attachment).
        </li></p>
    </ol>
    <p class="fw-bold"><li>Complaints and Feedback</li></p>
    <ol>
        <p><li>We always welcome feedback from Our customers and, whilst We always use 
        all reasonable endeavours to ensure that Your experience as a customer of 
        Ours is a positive one, We nevertheless want to hear from You if You have
        any cause for complaint.
        </li></p>
        <p><li>All complaints are handled in accordance with Our complaints handling policy 
        and procedure, available from support@entmcq.com
        </li></p>
        <p><li>If You wish to complain about any aspect of Your dealings with Us, please
        contact Us in one of the following ways:
        <ol>
            <li>By email, addressed at <a href="#"> support@entmcq.com</a></li>
        </ol>
        </li></p>
    </ol>
    <p class="fw-bold"><li>How We Use Your Personal Information (Data Protection)</li></p>
    <p>We will only use Your personal data as set out in Our Privacy Policy available from 
        www.entmcq.com including Cookie Policy.</p>
    <p class="fw-bold"><li>Other Important Terms</li></p>
    <ol>
        <p><li>We may transfer (assign) Our obligations and rights under these Terms of Sale (and under the Contract, as applicable) to a third party (this may happen, for example, if We sell Our business). If this occurs, You will be informed by Us in writing. Your rights under these Terms of Sale (and the Contract) will not be affected and Our obligations under these Terms of Sale (and the Contract) will be transferred to the third party who will remain bound by them.</li></p>
        <p><li>You may not transfer (assign) Your obligations and rights under these Terms
        of Sale (and under the Contract) without Our express written permission</li></p>
        <p><li>The Contract is between You and Us. No other person shall have any rights to 
        enforce any of its terms.</li></p>
        <p><li>If a court or other authority finds that any part(s) of these Terms of Sale are 
        unlawful, the remaining parts will remain in full force and effect.</li></p>
        <p><li>If We fail to take steps or delay in taking steps to enforce any of Our rights 
        against You under these Terms of Sale, that will not prevent Us doing so at a 
        later date, for example Our right to require You to make any payment which
        has become payable under the Contract.</li></p>
        <p><li>We may revise these Terms of Sale from time to time in response to changes
        in relevant laws and other regulatory requirements. If We change these Terms 
        of Sale as they relate to Your Subscription, We will give You reasonable 
        advance notice of the changes and provide details of how to cancel if You are
        not happy with them (also see sub-Clause 13.1 above).</li></p>
    </ol>
    <p class="fw-bold"><li>Law and Jurisdiction</li></p>
    <ol>
        <p><li>These Terms and Conditions, and the relationship between You and Us 
        (whether contractual or otherwise) shall be governed by, and construed in 
        accordance with, the law of [England & Wales] [Northern Ireland] [Scotland].
        </li></p>
        <p><li>As a Consumer, You will benefit from any mandatory provisions of the law in 
        Your country of residence. Nothing in sub-Clause 18.1 above takes away or 
        reduces Your rights as a consumer to rely on those provisions.</li></p>
        <p><li>As a Consumer, any dispute, controversy, proceedings or claim between You
        and Us relating to these Terms and Conditions, or the relationship between 
        You and Us (whether contractual or otherwise) shall be subject to the 
        jurisdiction of the courts of England, Wales, Scotland, or Northern Ireland, as 
        determined by Your residency.</li></p>
    </ol>
</ol>
<p class="fw-bold"><h5>Attachment</h5></p>
              <ol type='a'>
                <p><li>We only offer the Content online.</li></p>
                <p><li>We use technology which allows Us to provide the Content provided that You 
have the appropriate access to receive the Content.</li></p>
              </ol>
            </div>
            
            </div>
            </div>
            <Footer/>
            </div>
            </React.Fragment>
        
    );
}

export default Termsconditions;
