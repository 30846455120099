import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import MetaTags from "react-meta-tags";

function Student() {
  return (
    <React.Fragment>
      <MetaTags>
        <title>Medical Exam Preparation MCQ- our FRCS(ORL-HNS)Resource</title>
        <meta
          name="description"
          content="Prepare for your medical entrance exams with our practice tests and expert guidance.We offer the best exam preparation for ENT, Otology, Pediatrics, and more."
        />
        <meta
          property="og:title"
          content="Expert Medical Exam Prep FRCS (ORL-HNS)About us-ENT MCQ - Your Medical Exam Prep"
        />
        <meta
          property="keywords"
          content="step 1 practice questions, exam study plan, get preparation, surgery practice questions, frscs ent question bank, ent mcq with answer, frcs ent question bank, frcs exam preparation, ent exam questions, mcq dataset, ent mcq with answers, ent question bank, ent mcqs free online, ENT FRCS exams, ent mcq, mcq ent, Frcs"
        />
        <link rel="canonical" href={window.location.href} />
      </MetaTags>
      <div class="container-fluid">
        <div class="row">
          <Header />
        </div>
      </div>

      <div class="container-fluid" id="aboutus">
        <div class="row">
          <div class="col-sm-12">
            <div class="container">
              <div class="row align-items-center">
                <div class="col-sm-6 col-12 d-flex justify-content-between">
                  <h1>About the Exam</h1>
                </div>
                {/* <div class="col-sm-6 col-12 d-flex justify-content-between">
                                    
                                    <nav aria-label="breadcrumb">
                                        <ol class="breadcrumb mb-0" id="breadcrumb">
                                            <li class="breadcrumb-item"><a href="#">Home</a></li>
                                            <li class="breadcrumb-item active" aria-current="page">About Exam</li>
                                        </ol>
                                    </nav>
                                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container pb-5" id="main-content">
        <div class="row">
          <div class="col-lg-6">
            <h3 class="mb-4" style={{ fontWeight: "bold" }}>
              About the Exam
            </h3>
            <p style={{ textAlign: "justify" }}>
              As with all major examinations, whether it be medical school
              finals, membership examinations to a royal college, or major
              non-academic examinations, anxiety, stress and fear of the unknown
              can be overwhelming. The FRCS (ORL-HNS) is no exception to that
              rule, but can often be harder to plan and prepare for due to
              having to balance working full-time, perhaps managing family and
              social commitments and other stresses alongside preparing for what
              may arguably be the toughest examination you are to ever sit in
              your life!
            </p>
            <p style={{ textAlign: "justify" }}>
              This website aims to give you pertinent information related to
              this examination, particularly the Section One examination, and
              will assist you in preparing by consolidating theory into
              examination practice, providing a platform to apply your knowledge
              and practice efficiently whilst building on previous learning.
            </p>
            <p style={{ textAlign: "justify" }}>
              The FRCS (ORL-HNS) is the final hurdle often faced by many, prior
              to preparing to complete higher surgical training and become a
              consultant, whether it be through the formal surgical specialty
              training pathway to CCT, or via your own custom-created pathway of
              training to CESR.
            </p>
            {/* <p class="fw-bold">Our unique features:</p>
                <ul>
                    <li>The only website that exists specifically for this particular examination, unlike other generic multiple choice websites</li>
                    <li>Designed and maintained by UK doctors who have passed this examination</li>
                    <li>Option for practicing by sub-specialty or random generated options</li>
                    <li>Option to time yourself and perform &lsquo;mock exams&rsquo; in timed modes</li>
                    <li>Has answers to questions as well as more information/detail or links to resources</li>
                    <li>Option to be paid by us if you submit questions for our SBA question bank</li>
                </ul> */}
          </div>
          <div class="col-sm-6">
            <img
              src="/assets/images/aboutus.svg"
              class="img-fluid"
              style={{ height: 500 }}
            />
          </div>
          <div class="col-sm-12">
            <h5 class="mb-4">About the Section One Examination</h5>
            <p style={{ textAlign: "justify" }}>
              The FRCS (ORL-HNS) Section One examination usually has two
              sittings per year, most commonly in January and July. For our
              international colleagues working outside of the UK wishing to sit
              this examination, the sittings are usually in March and November.
              In the UK, the exam is now undertaken at a registered test
              examination centre, similar to venues that host driving tests and
              other professional qualification examinations. The centres are
              widespread all over the UK, and once registered for the
              examination, you can select your preferred/local test centre. They
              are usually at Person Vue test centres and confirmation is
              received by candidates with plenty of relevant information, an
              opportunity to practice the software system interface and clear
              directions and advice.
            </p>
            <p style={{ textAlign: "justify" }}>
              To be eligible to sit the examination, you must complete an online
              application form and submit three consultant colleagues’ completed
              structured referee forms. Our advice is to start preparing for the
              process early, as it can often take time to chase busy consultants
              to complete your paperwork and it is mandatory to be completed to
              enable you to submit your application. In addition to the three
              referee forms, you will also need to submit photo identification
              (Driving License/Passport electronic copy), your curriculum vitae
              and a summary of your operative experience. For our international
              colleagues, the administrative process is slightly different so
              please do visit the joint surgical colleges fellowship examination
              (JSCFE) website well in advance –www.jscfe.co.uk
            </p>
            <p style={{ textAlign: "justify" }}>
              The joint committee on intercollegiate examinations (JCIE) website
              is an excellent resource to learn more information, confirm the
              application submission deadlines, examination dates and has the
              actual link to ‘apply’ -www.jcie.org.uk
            </p>
            <p style={{ textAlign: "justify" }}>
              This website is crucial to visit to understand more about the
              examination and the process, and provides vital documents such as
              Guidance Notes for Applicants/Referees
            </p>
            <p style={{ textAlign: "justify" }}>
              For example, January examination diets/sittings require your
              completely submitted application in September of the previous
              year. July examination diets/sittings require your completely
              submitted application in April of the same year (less than 3
              months prior). For international candidates the deadlines are June
              for the November sitting and the previous November for the March
              sitting. It is easy to miss these deadlines, and even easier to
              not have your completed referee forms or other documentation.
            </p>
            <p style={{ textAlign: "justify" }}>
              The current cost of the FRCS (ORL-HNS) examination is £1,904, this
              payment is the fee for both parts, Section One and Two, and cannot
              be paid separately. The FRCS (ORL-HNS) Section One examination is
              a one day examination sitting, consisting of two ‘single best
              answer’, multiple choice option type papers which are displayed
              electronically on a computer screen for you to select your
              answers. Each paper has 120 questions, and is 2hours and
              15minutes. You will be allowed to leave sooner than the allocated
              time for the examination if you finish early, and there is
              normally an allocated break between the two papers where you can
              leave the test centre if you desire and return at the agreed time
              between you and the examination supervisor at the local testing
              centre.
            </p>
            <p style={{ textAlign: "justify" }}>
              Reassuringly, there IS plenty of time to complete the examination
              papers and you have the opportunity to flag questions during the
              electronic examination platform use, can return to questions and
              modify answers, skip and return etc. It is crucial to answer every
              single question as there is NO NEGATIVE MARKING, and answering
              therefore always gives you a chance to score marks even if you do
              not know the answer. Please remember, questions may just be lines
              of text with a question and some answer options and may include an
              image/video as part of the dialogue leading up to the actual
              question. Some questions may be obscure, if very random, try not
              to linger on them too long, there is a high chance they will be
              removed and discarded – many questions are new or test questions
              to trial prior to consideration of implementing them in future
              examination diets. Our website it the closest resource available
              to the actual examination so please do feel free to maximize its
              use for your benefit, to improve your chances of success – Good
              luck!
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </React.Fragment>
  );
}

export default Student;
